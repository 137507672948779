<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page

========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <div class="flex justify-center items-center pb-8">
        <div class="flex w-full justify-center">
          <img
            src="@/assets/images/duotalk-logo.png"
            alt="logo duotalk"
            height="36"
            title="logo"
          />
        </div>
      </div>
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/forgot-password.png"
                alt="login"
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">{{ $t("ResetPassword") }}</h4>
                  <p>{{ $t("ResetPasswordMessageWindow") }}.</p>
                </div>

                <vs-input
                  v-validate
                  data-vv-validate-on="blur"
                  name="email"
                  icon-no-border
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="Email"
                  v-model="email"
                  class="w-full mb-8"
                  @keyup.enter="resetPass"
                />
                <vs-button
                  class="float-right px-4 w-full md:w-auto"
                  @click="resetPass"
                  >{{ $t("ResetPassword") }}</vs-button
                >
                <vs-button
                  type="border"
                  to="/login"
                  class="px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  >{{ $t("GoBack") }}</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    resetPass() {
      if (this.email.length > 4) {
        this.$vs.loading();
        const payload = {
          userDetails: {
            email: this.email.toLowerCase(),
          },
          notify: this.$vs.notify,
        };
        this.$store
          .dispatch("auth/resetPassword", payload)
          .then(() => {
            this.$vs.loading.close();
            this.email = "";
            this.$vs.notify({
              title: this.$t("Ok"),
              text: this.$t("ResetPasswordMessage"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
              position: "top-right",
              time: 4000,
            });
          })
          .catch((error) => {
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            this.$vs.loading.close();
            this.$vs.notify({
              title: this.$t("Error"),
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
              time: 4000,
            });
          });
      } else {
        this.$vs.loading.close();
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("InvalidEmail"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      }
    },
  },
};
</script>
